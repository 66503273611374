import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { OperatingStatus } from 'src/api/api-site-alerts';

import { PaginatedResponse } from 'clipsal-cortex-types/src/common/types';

import { get } from '../../api/api-helpers';
import { SiteData } from '../../api/api-site';
import { RootState } from '../../app/store';

type SitesResponse = PaginatedResponse<SiteData>;

/* Fetch activeSites based on pageSize, pageCount, tabIndex and searchTerm*/
export const getSites = createAsyncThunk<
  { data: SiteData[]; item_count: number },
  {
    pageSize: number;
    offset: number;
    searchTerm?: string;
    orderBy?: string;
    sortOrder?: string;
    operatingStatus?: OperatingStatus | 'all';
    tenantId?: number;
  },
  { state: RootState }
>(
  'user/getActiveSites',
  async ({
    searchTerm,
    pageSize,
    offset,
    orderBy = 'clipsal_solar_id',
    sortOrder = 'DESC',
    operatingStatus = 'all',
    tenantId,
  }) => {
    const orderDirection = sortOrder === 'DESC' ? '-' : '';

    let uri = `/fleet/sites?limit=${pageSize}&offset=${offset}&ordering=${orderDirection + orderBy}`;

    if (tenantId) uri += `&tenant_id=${tenantId}`;

    if (operatingStatus !== 'all') uri += `&operating_status=${operatingStatus.toUpperCase()}`;

    if (searchTerm) uri += `&search_term=${searchTerm}`;

    return await get<SitesResponse>(uri);
  }
);

type DashboardSitesState = {
  sites: SiteData[];
  sitesCount: number;
  isLoaded: boolean;
  isFetchMoreDataLoaded: boolean;
  lastVisitedSitePath: string;
};

const initialState: DashboardSitesState = {
  sites: [],
  sitesCount: 0,
  isLoaded: false,
  isFetchMoreDataLoaded: true,
  lastVisitedSitePath: '/dashboard',
};

export const dashboardSitesSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setNotLoaded: (state) => {
      return {
        ...state,
        isLoaded: false,
      };
    },
    setLastVisitedSitePath: (state, action: PayloadAction<string>) => {
      return {
        ...state,
        lastVisitedSitePath: action.payload,
      };
    },
    resetDashboard: (state) => {
      // reset everything except lastVisitedSitePath
      return { ...initialState, lastVisitedSitePath: state.lastVisitedSitePath };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSites.pending, (state) => {
        return {
          ...state,
          isFetchMoreDataLoaded: false,
        };
      })
      .addCase(getSites.fulfilled, (state, action) => {
        return {
          ...state,
          sites: action.payload.data,
          sitesCount: action.payload.item_count,
          isLoaded: true,
          isFetchMoreDataLoaded: true,
        };
      });
  },
});

export const { setNotLoaded, setLastVisitedSitePath, resetDashboard } = dashboardSitesSlice.actions;

export const selectDashboardSites = (state: RootState) => {
  return state.dashboard;
};

export default dashboardSitesSlice.reducer;

import React from 'react';
import { createIcon } from '@chakra-ui/react';

import evChargerImg from './images/ev-charger.png';

// eslint-disable max-len
export const SchneiderChargeIcon = createIcon({
  displayName: 'SchneiderChargeIcon',
  path: (
    <svg
      width="24"
      height="33"
      viewBox="0 0 24 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <rect width="24" height="33" fill="url(#pattern0_2358_355)" />
      <defs>
        <pattern id="pattern0_2358_355" patternContentUnits="objectBoundingBox" width="1" height="1">
          <use xlinkHref="#image0_2358_355" transform="matrix(0.000361842 0 0 0.000263158 0.0138651 0)" />
        </pattern>
        <image id="image0_2358_355" width="2687" height="3800" href={evChargerImg} />
      </defs>
    </svg>
  ),
  viewBox: '0 0 24 33',
});

import { SiteDeviceConnection } from 'clipsal-cortex-types/src/api/api-site-device-connection';

import { baseApi } from '../../../../app/baseApi';

export const siteDeviceConnectionApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getSiteDeviceConnections: builder.query<SiteDeviceConnection[], number>({
      query: (siteDeviceId) => ({
        url: `/v1/site_devices/${siteDeviceId}/connections`,
        method: 'GET',
      }),
      providesTags: ['SiteDeviceConnections'],
    }),
    createSiteDeviceConnection: builder.mutation<
      SiteDeviceConnection,
      { siteDeviceId: number; body: Pick<SiteDeviceConnection, 'child_id' | 'connection_type'> }
    >({
      query: ({ siteDeviceId, body }) => ({
        url: `/v1/site_devices/${siteDeviceId}/connections`,
        method: 'POST',
        body,
      }),
      invalidatesTags: ['SiteDeviceConnections'],
    }),
    checkConnectionStatus: builder.mutation<Pick<SiteDeviceConnection, 'connection_status'>, number>({
      query: (siteDeviceConnectionId) => {
        return {
          url: `/v1/site_device_connections/${siteDeviceConnectionId}/check_connection_status`,
          method: 'POST',
        };
      },
      invalidatesTags: ['SiteDeviceConnections'],
    }),
    deleteSiteDeviceConnection: builder.mutation<void, number>({
      query: (connectionId) => ({
        url: `/v1/site_device_connections/${connectionId}`,
        method: 'DELETE',
        body: {},
      }),
      invalidatesTags: ['SiteDeviceConnections'],
    }),
    sapnRegistration: builder.mutation<void, number>({
      query: (siteId) => ({
        url: `/fleet/sites/${siteId}/sapn_registration`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useGetSiteDeviceConnectionsQuery,
  useCreateSiteDeviceConnectionMutation,
  useCheckConnectionStatusMutation,
  useDeleteSiteDeviceConnectionMutation,
  useSapnRegistrationMutation,
} = siteDeviceConnectionApi;

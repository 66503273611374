import { ComponentWithAs, IconProps } from '@chakra-ui/react';

import { SCHNEIDER_ELECTRIC_MANUFACTURER_ID } from 'clipsal-cortex-utils/src/constants/common-constants';

import {
  AirConIcon,
  BackupCircuitIcon,
  BatteryIcon,
  CircuitPlaceholderIcon,
  CommonAreaIcon,
  EvChargerIcon,
  FridgeIcon,
  GarageIcon,
  GridConnectionIcon,
  HomeIcon,
  HotWaterIcon,
  KitchenIcon,
  LaundryIcon,
  LightIcon,
  MachineIcon,
  OfficeIcon,
  OtherIcon,
  OvenIcon,
  PoolPumpIcon,
  PowerPointIcon,
  ShedIcon,
  SolarBatteryHybridIcon,
  SolarHotWaterIcon,
  SolarPVIcon,
  SpaIcon,
  StoveIcon,
  StudioIcon,
  SubboardIcon,
  TenantIcon,
  UnderFloorHeatingIcon,
} from './custom-appliance-icons';
import { SchneiderChargeIcon } from './ev-charger-icons';

export const CIRCUIT_TYPE_TO_ICON: Record<string, ComponentWithAs<'svg', IconProps>> = {
  load_air_conditioner: AirConIcon,
  load_powerpoint: PowerPointIcon,
  load_pool: PoolPumpIcon,
  load_hot_water: HotWaterIcon,
  load_lighting: LightIcon,
  load_oven: OvenIcon,
  load_stove: StoveIcon,
  load_ev_charger: EvChargerIcon,
  pv_site_net: SolarPVIcon,
  ac_load_net: GridConnectionIcon,
  battery_storage: BatteryIcon,
  hybrid_inverter: SolarBatteryHybridIcon,
  load_ev: EvChargerIcon,
  backup_circuit: BackupCircuitIcon,
  load_common_area: CommonAreaIcon,
  load_refrigerator: FridgeIcon,
  load_garage: GarageIcon,
  ac_load: GridConnectionIcon,
  load_kitchen: KitchenIcon,
  load_laundry: LaundryIcon,
  load_machine: MachineIcon,
  not_set: CircuitPlaceholderIcon,
  load_office: OfficeIcon,
  load_other: OtherIcon,
  load_shed: ShedIcon,
  pv_site: SolarPVIcon,
  load_hot_water_solar: SolarHotWaterIcon,
  load_spa: SpaIcon,
  load_studio: StudioIcon,
  load_subboard: SubboardIcon,
  load_tenant: TenantIcon,
  load_underfloor_heating: UnderFloorHeatingIcon,
  load_hot_water_heat_pump: HotWaterIcon,
  load_residual: HomeIcon,
};

export const MANUFACTURER_ID_TO_EV_CHARGER_ICON: Record<string, ComponentWithAs<'svg', IconProps>> = {
  [SCHNEIDER_ELECTRIC_MANUFACTURER_ID]: SchneiderChargeIcon,
};

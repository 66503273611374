import React, { useEffect, useState } from 'react';
import { Accordion, Alert, AlertIcon, Box, Flex, Text, useToast } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { SwitchSchedule, SwitchScheduleToCreate } from 'clipsal-cortex-types/src/api/api-switch-schedule';
import CenteredLoader from 'clipsal-cortex-ui/src/components/CenteredLoader';

import CustomButton from '../../../../common/components/CustomButton';
import { COMPONENT_MIN_HEIGHT } from '../../../../common/constants';
import { useNavigationState } from '../../../../context/NavigationProvider';
import { selectSite } from '../../siteSlice';
import { selectAssignments } from '../../system-details/systemDetailsSlice';
import { selectRawMeters } from '../meterSetupSlice';
import SubRouteTopNav from '../SubRouteTopNav';
import { mapIntelligentControlToAPI, mapSwitchesToApi, mapSwitchesToForm } from './form-mapping-helpers';
import { validateSchedules } from './scheduler/utils';
import { SwitchConfigFormData } from './switch-config-form-types';
import { switchConfigFormSchema } from './switch-config-validation-schema';
import {
  useCreateSwitchIntelligentControlsMutation,
  useCreateSwitchSchedulesMutation,
  useDeleteSwitchSchedulesMutation,
  usePatchSwitchesMutation,
  useSwitches,
  useUpdateSwitchIntelligentControlsMutation,
  useUpdateSwitchSchedulesMutation,
  useUpdateSwitchStateProfilesMutation,
} from './switchApi';
import SwitchConfiguration from './SwitchConfiguration';
import useSwitchAppliance from './useSwitchAppliance';

const DEFAULT_FORM_VALUES = {
  meters: [],
};

const SwitchConfigurationContainer = () => {
  const {
    register,
    control,
    reset,
    setValue,
    getValues,
    setError,
    clearErrors,
    trigger,
    handleSubmit,
    formState: { errors, isDirty },
  } = useForm<SwitchConfigFormData>({
    defaultValues: DEFAULT_FORM_VALUES,
    resolver: yupResolver(switchConfigFormSchema),
  });
  const navigate = useNavigate();
  const { setNavigationState } = useNavigationState();
  const site = useSelector(selectSite);
  const rawMeters = useSelector(selectRawMeters);
  const assignments = useSelector(selectAssignments);
  const appliances = useSwitchAppliance();
  const { switches, switchStateProfiles, isLoading, isSwitchesError } = useSwitches();
  const [patchSwitches] = usePatchSwitchesMutation();
  const [isSavingSwitches, setIsSavingSwitches] = useState(false);
  const toast = useToast({ status: 'error', isClosable: true });
  const [updateSwitchSchedules] = useUpdateSwitchSchedulesMutation();
  const [createSwitchSchedules] = useCreateSwitchSchedulesMutation();
  const [deleteSwitchSchedules] = useDeleteSwitchSchedulesMutation();
  const [updateIntelligentControls] = useUpdateSwitchIntelligentControlsMutation();
  const [createIntelligentControls] = useCreateSwitchIntelligentControlsMutation();
  const [updateStateProfiles] = useUpdateSwitchStateProfilesMutation();

  useEffect(() => {
    if (!isLoading && !isSwitchesError) {
      reset(mapSwitchesToForm(Object.values(rawMeters), switches, appliances, assignments, switchStateProfiles));
    }
  }, [isLoading, isSwitchesError, switches]);

  function validateManualSchedules(values: SwitchConfigFormData) {
    let hasError = false;

    for (const [meterIndex, meter] of values.meters.entries()) {
      for (const [switchIndex, siteSwitch] of meter.switches.entries()) {
        if (
          siteSwitch.active &&
          siteSwitch.schedulingType === 'TIMED' &&
          siteSwitch.schedulingConfig?.MANUAL?.schedules?.length
        ) {
          // We need some fairly complex validation for site switch schedules which compare against other schedules
          // and switches, putting this into yup's schema would overcomplicate things here, so validation is done
          // manually
          const validationResult = validateSchedules(siteSwitch.schedulingConfig.MANUAL.schedules);

          if (validationResult.type === 'ERROR') {
            hasError = true;
            setError(
              `meters.${meterIndex}.switches.${switchIndex}.schedulingConfig.MANUAL.schedules.${
                validationResult.scheduleIndex as number
              }`,
              { message: validationResult.message }
            );
          }
        }
      }
    }

    return hasError;
  }

  async function handleSaveSwitches(values: SwitchConfigFormData) {
    const siteSwitches = mapSwitchesToApi(values);
    await patchSwitches(siteSwitches.map((s) => ({ switchId: s.id, body: s }))).unwrap();
  }

  async function handleSaveManualSchedules(values: SwitchConfigFormData) {
    const switchesWithManualSchedulesEnabled = values.meters.flatMap((meter) =>
      meter.switches.filter((s) => s.active && s.schedulingType === 'TIMED')
    );

    // Save schedules for each
    for (const siteSwitch of switchesWithManualSchedulesEnabled) {
      const schedulesToCreate = siteSwitch
        .schedulingConfig!.MANUAL!.schedules.filter((s) => !s.startScheduleId)
        .flatMap<SwitchScheduleToCreate>((newSchedule) => {
          return [
            {
              switch_id: siteSwitch.id,
              event_time: newSchedule.startTime,
              weekly_freq_interval: newSchedule.daysOfWeek,
              event_action: 'closed',
              active: true,
              externally_controlled: false,
            },
            {
              switch_id: siteSwitch.id,
              event_time: newSchedule.endTime,
              weekly_freq_interval: newSchedule.daysOfWeek,
              event_action: 'open',
              active: true,
              externally_controlled: false,
            },
          ];
        });

      const schedulesToUpdate = siteSwitch
        .schedulingConfig!.MANUAL!.schedules.filter((s) => s.startScheduleId)
        .flatMap<SwitchSchedule>((existingSchedule) => {
          return [
            {
              schedule_id: existingSchedule.startScheduleId!,
              switch_id: siteSwitch.id,
              event_time: existingSchedule.startTime,
              weekly_freq_interval: existingSchedule.daysOfWeek,
              event_action: 'closed',
              active: true,
              externally_controlled: false,
            },
            {
              schedule_id: existingSchedule.endScheduleId!,
              switch_id: siteSwitch.id,
              event_time: existingSchedule.endTime,
              weekly_freq_interval: existingSchedule.daysOfWeek,
              event_action: 'open',
              active: true,
              externally_controlled: false,
            },
          ];
        });

      const switchPromises: Promise<null | SwitchSchedule[]>[] = [
        updateSwitchSchedules(
          schedulesToUpdate.map((schedule) => ({ scheduleId: schedule.schedule_id, schedule }))
        ).unwrap(),
        createSwitchSchedules({
          switchId: siteSwitch.id,
          schedules: schedulesToCreate,
        }).unwrap(),
      ];

      if (siteSwitch.schedulingConfig?.MANUAL?.deletedScheduleIds?.length) {
        switchPromises.push(deleteSwitchSchedules(siteSwitch.schedulingConfig.MANUAL.deletedScheduleIds).unwrap());
      }

      await Promise.all(switchPromises);
    }
  }

  async function handleSaveIntelligentControls(values: SwitchConfigFormData) {
    const intelligentControls = values.meters
      .flatMap((m) => m.switches.filter((s) => s.schedulingType === 'AUTO'))
      .map((s) => ({ switchId: s.id, config: s.schedulingConfig!.AUTO! }));

    const newIntelligentControls = intelligentControls.filter(({ config }) => !config.isExisting);
    const existingIntelligentControls = intelligentControls.filter(({ config }) => config.isExisting);

    await Promise.all([
      updateIntelligentControls(
        existingIntelligentControls.map(({ switchId, config }) => ({
          switchId,
          body: mapIntelligentControlToAPI(config),
        }))
      ).unwrap(),
      createIntelligentControls(
        newIntelligentControls.map(({ switchId, config }) => ({
          switchId,
          body: mapIntelligentControlToAPI(config),
        }))
      ).unwrap(),
    ]);
  }

  async function handleSaveStateProfiles(values: SwitchConfigFormData) {
    const activeSwitches = values.meters.flatMap((m) => m.switches).filter((s) => s.active);

    try {
      await updateStateProfiles(
        activeSwitches.map((s) => ({
          switchId: s.id,
          body: { device_state: { switches: { [s.oemId]: s.fallbackState } } },
        }))
      ).unwrap();
    } catch (error) {
      toast({
        title: 'Something went wrong saving switch fallback states',
        description: 'Please try again. If this problem persists, contact an administrator.',
      });
    }
  }

  const handleFormSubmit = async (values: SwitchConfigFormData) => {
    const hasError = validateManualSchedules(values);

    // Don't save if validation fails
    if (hasError) {
      toast({
        title: 'There are errors with your schedules.',
        description: 'Check each switch to make sure your schedules are valid.',
      });
      return;
    }

    setIsSavingSwitches(true);

    try {
      await Promise.all([
        handleSaveSwitches(values),
        handleSaveManualSchedules(values),
        handleSaveIntelligentControls(values),
        handleSaveStateProfiles(values),
      ]);

      setIsSavingSwitches(false);
      toast({ title: 'Successfully saved switch configuration!', status: 'success' });
      setNavigationState({ direction: 'backward' });
      navigate(`/site/${site.clipsal_solar_id}/meter_setup/meters`);
    } catch (e) {
      setIsSavingSwitches(false);
      toast({
        title: 'Something went wrong saving switch data.',
        description: 'Please try again. If this problem persists, contact an administrator.',
      });
    }
  };

  if (isLoading) return <CenteredLoader minHeight={300} />;

  return (
    <Box w="100%" minHeight={COMPONENT_MIN_HEIGHT} data-testid="switch-configuration-form">
      <SubRouteTopNav
        onGoBack={() => {
          if (isDirty) {
            if (
              window.confirm(
                `Are you sure you want to leave the page without saving the switch configuration? Any changes will be discarded.`
              )
            ) {
              navigate(-1);
            }
          } else {
            navigate(-1);
          }
        }}
        title="Switch Configuration"
      />

      <Text ml={4} my={4}>
        Configure switches for the site
      </Text>

      {isSwitchesError ? (
        <Alert status="error" variant="left-accent">
          <AlertIcon />
          There was an error communicating with your switches. Please check their connection. If this problem persists,
          please contact support.
        </Alert>
      ) : (
        <Flex>
          <Accordion allowToggle w="100%" defaultIndex={0}>
            <SwitchConfiguration
              {...{ register, control, reset, setValue, getValues, setError, clearErrors, trigger, errors }}
            />
          </Accordion>
        </Flex>
      )}

      <CustomButton
        data-testid="save-switch-config-btn"
        isDisabled={!isDirty}
        minW={260}
        onClick={handleSubmit(handleFormSubmit)}
        isLoading={isSavingSwitches}
      >
        Save Switch Configuration
      </CustomButton>
    </Box>
  );
};

export default SwitchConfigurationContainer;
